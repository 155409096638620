import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allContentfulAccreditations(sort: { fields: [order], order: ASC }) {
          edges{
            node{
              image{
                image{
                  fluid {
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                  }
                }
              }
              title
              text{
                childMarkdownRemark{
                  html
                }
              }
              link    
            }
          }
        }
      }
    `}
    render={ data => (
      <section className="section accreditations">
        <div className="container content-container">
          <h3 className="title is-size-5 is-size-6-mobile">Accreditations</h3>
          <div className="columns">
            {data.allContentfulAccreditations.edges.map(({ node }, index) => (
              <div key={index} className="column is-one-third-desktop is-full-mobile">
                <a aria-label={'Click here to visit  ' + node.title + ' website'} className="accreditations__logo-link has-text-centered is-full" href={node.link}>
                  <figure className="image is-x300x300">
                    {/*<Img fluid={node.image.image.fluid.src} alt={node.image.altText} />*/}
                    <Img fluid={node.image.image.fluid} />
                  </figure>
                </a>
                <h3 className="title is-size-6 is-size-7-mobile">{node.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: node.text.childMarkdownRemark.html }} />
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
)