import React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/layout/hero"
import Navbar from "../components/layout/navbar"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Accreditations from "../components/accreditations"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
  <Layout>
    <SEO />
    <Hero
      hero={data.forestHero.desktopImage.fluid}
      heroMobile={data.forestHeroMobile.mobileImage.fluid}
      logo={data.logo.childImageSharp.fixed}
      arb={data.arb.childImageSharp.fixed}
      strapline="Scotland’s Premier Arboriculture & <br /> Commercial Tree Surgeon"
      straplineMobile="Scotland’s Premier Arboriculture<br /> & Commercial Tree Surgeon"/>
    <Navbar/>
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <div className="">
              <h1 className="title is-size-5 is-size-6-mobile">Welcome to Caledonian Tree Services</h1>
              <p>
                Caledonian Tree Services is Scotland’s premier arboriculture specialist. We offer a wide range of
                services throughout Renfrewshire,
                Ayrshire, Glasgow and the Scottish central belt. We’re willing to take on any tree work you may have. So
                why not give us a call & get
                that job you been putting off done to a professional standard.
              </p><br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <figure className="image is-x352x264">
              <Img fluid={data.mulch.childImageSharp.fluid} fadeIn={false} />
            </figure>
          </div>
          <div className="column is-hidden-mobile">
            <figure className="image is-x352x264">
              <Img fluid={data.sectional.childImageSharp.fluid} fadeIn={false} />
            </figure>
          </div>
          <div className="column is-hidden-mobile">
            <figure className="image is-x352x264">
              <Img fluid={data.tractor.childImageSharp.fluid} fadeIn={false} />
            </figure>
          </div>
          <div className="column">
            <figure className="image is-x352x264">
              <Img fluid={data.logs.childImageSharp.fluid} fadeIn={false} />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <Accreditations />
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <div className="">
              <h2 className="title is-size-5 is-size-6-mobile">Why choose Caledonian Tree Services?</h2>
              <div className="columns">
                <div className="column is-half is-hidden-mobile">
                  <ul className="notification">
                    <li>All tree work is undertaken.</li>
                    <li>All job estimates are completely free of charge.</li>
                    <li>All jobs carried out to the standard you want.</li>
                    <li>All works carried out to industry best practice</li>
                  </ul>
                </div>
                <div className="column is-half is-hidden-mobile">
                  <ul className="notification is-primary">
                    <li>All staff qualified Arborists and 2 qualified Foresters</li>
                    <li>Comfortably Serve Renfrewshire and Central Belt</li>
                    <li>We are insured to a sum of £10,000,000 allowing you complete peace of mind.</li>
                    <li>Knowledgeable &amp; friendly staff</li>
                  </ul>
                </div>
                <div className="column is-hidden-tablet">
                  <p>All tree work is undertaken.</p>
                  <p>All job estimates are completely free of charge.</p>
                  <p>All jobs carried out to the standard you want.</p>
                  <p>All works carried out to industry best practice</p>
                  <p>All staff qualified Arborists and 2 qualified Foresters</p>
                  <p>Comfortably Serve Renfrewshire and Central Belt</p>
                  <p>We are insured to a sum of £10,000,000 allowing you complete peace of mind.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <div className="">
              <h2 className="title is-size-5 is-size-6-mobile">Our Background in Renfrewshire & Scotland</h2>
              <p>
                Colin Wilson, the managing director Of Caledonian Tree Services Ltd., set up business after 3 years of studying
                Forestry and Arboriculture at the National School of Forestry at Newton Rigg.

                With an armful of qualifications and a desire to put his training to use, Colin sought help and guidance
                from the Princes Trust and the small business gateway. 7 years on Colin has a thriving business built up
                from word of mouth by satisfied customers.
              </p><br />
              <p>
                We are based in Kilmalcolm, Renfrewshire and offer our services throughout the central belt of Scotland and
                surrounding areas. Over the past seven years we have established a good client base especially locally in
                Inverclyde and Renfrewshire. We have put together a strong, devoted and conscientious workforce and also
                own and run all of our own forestry machinery.
              </p><br />
              <p>
                We Supply a broad range of arboricultural and tree clearing solutions with very few jobs being beyond our
                expertise and capabilities. The services we supply include woodland maintenance, tree and site clearance,
                tree surgery, timber extraction including winching, tree felling and removal, forwarding, hedge maintenance
                and removal, wood chipping including brash, whole tree, and restricted access. We offer a tree stump grinding service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)}

export const query = graphql`
  query {
    forestHero: contentfulHeroImage(desktopImage: {title: {eq: "forest-hero"}}) {
      desktopImage{
        fluid {
          aspectRatio
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
    forestHeroMobile: contentfulHeroImage(mobileImage: {title: {eq: "forest-hero-mobile"}}) {
      mobileImage {
        fluid {
          aspectRatio
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mulch: file(relativePath: { eq: "index/mulch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sectional: file(relativePath: { eq: "index/sectional.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tractor: file(relativePath: { eq: "index/tractor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logs: file(relativePath: { eq: "index/logs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arb: file(relativePath: { eq: "ARB-Approved2.png" }) {
      childImageSharp {
        fixed(width: 125, height: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
